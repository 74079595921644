











































































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Image, Sticky } from "vant";
import RankNavBar from "@/components/RankNavbar.vue";

@Component({
  components: {
    RankNavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Sticky.name]: Sticky,
  },
})
export default class RankDetail extends Mixins(Mixin) {
  rankDetailMessage: good.BillboardInfoDto = {};
  bg = 1;
  title = "";
  @Watch("$route")
  routerChange(): void {
    if (this.$route.name == "rankdetail") this.recieveRankDetail();
  }

  mounted(): void {
    this.recieveRankDetail();
    const { rankIndex = 0 } = this.$route.params;
    this.bg = (Number(rankIndex) % 3) + 1; // 按照下标依次切换背
    // chrome|火狐浏览器
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  recieveRankDetail(): void {
    this.$api.goodApi.billboard.getBillboardByCityCode(
      Number(this.$route.params.detailId),
      ({ data }) => {
        this.rankDetailMessage = data;
        if (data.title) {
          this.title = data.title;
        }
      }
    );
  }
  goProDetail(item: good.RecommendProductDto): void {
    let theaterId = this.TheaterModule.theaterId;
    this.jsBridge(
      "goProDetail",
      {
        productId: item.productId,
        theaterId: theaterId,
        taskId: "",
      },
      () => {
        this.goToProjectDetail(item.productId, item.tourId, item.tour);
      }
    );
  }
  toRankList(): void {
    this.$router.push(`/rank-list`);
  }
}
